<script lang="ts" setup></script>
<template>
  <div>
    <slot name="app-before" />
    <div id="app-before"></div>
    <div class="flex flex-col min-h-screen">
      <slot name="header">
        <PageNavbar>
          <!-- <template #drawer>
            <DashboardSidebar mode="mobile" />
          </template> -->
        </PageNavbar>
      </slot>
      <div class="flex-1 w-full flex flex-col">
        <div
          class="relative flex-1 flex flex-col mx-auto max-w-8xl w-full h-full"
        >
          <slot />
        </div>
        <slot name="footer">
          <PageFooter />
        </slot>
      </div>
    </div>
    <slot name="app-after" />
    <div id="app-after"></div>
  </div>
</template>
